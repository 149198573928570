import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import {Link} from "@mui/material";
import theme from '../../theme';

const myStyles = {
	link: {
		fontSize:'1rem',
		margin: theme.spacing(1, 1.5),
		cursor: 'pointer',
		[theme.breakpoints.down("sm")]: {
			fontSize:'1rem',
		},
		"&:hover": {
			color: 'white'
		}
	},
};

const listLanguages = ['en', 'fr'];

const TranslateLink = () => {
	const { t } = useTranslation();
	const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
	const [index, setIndex] = useState(listLanguages.indexOf(currentLanguage));

	const handleLangChange = () => {
		const newIndex =
			(index + 1) % listLanguages.length;
		i18next.changeLanguage(listLanguages[newIndex]);
		setCurrentLanguage(listLanguages[newIndex])
		setIndex(newIndex);
	};

	return (
		<Link variant="button" color="textPrimary" onClick={handleLangChange} sx={myStyles.link}>
			{currentLanguage.toUpperCase()}
		</Link>
	);
}

export default TranslateLink;