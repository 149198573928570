import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Alert, Snackbar} from '@mui/material';

function ToastMsg(props) {
	const {t} = useTranslation();

	const [open, setOpen] = useState(props?.open ? props.open : false);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	return (
		<Snackbar
			anchorOrigin={{vertical: 'top', horizontal: 'right'}}
			open={open}
			autoHideDuration={4000}
			onClose={handleClose}
			key={'top' + 'right'}
		>
			<Alert onClose={handleClose} severity={props.type} sx={{ width: '100%' }}>
				{props.message}
			</Alert>
		</Snackbar>
	);
}

export default ToastMsg;