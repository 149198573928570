import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import apiClient from '../../../services/apiClient';
import AlreadyConfirmed from './AlreadyConfirmed';
import {CircularProgress, Container, Grid} from "@mui/material";


const myStyles = {
	container: {
		margin:"auto",
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		minHeight: "100vh",
		padding: "100px 0",
	},
	item: {
		border: "1px solid lightGray",
		borderRadius:"20px",
		padding:"50px",
	}
};

function ConfirmAccount(props) {
	const {t} = useTranslation();
	const { token } = useParams();
	const navigate = useNavigate();

	const [errors, setErrors] = useState({});
	const [isFetching, setIsFetching] = useState(false)
	const [userEmail, setUserEmail] = useState('')

	useEffect(() => {
		const fetchAccountConfirmed = async () => {
			setIsFetching(true)
				const {data, error} = await apiClient.fetchAccountConfirmed(token)
				if (data){
					setUserEmail(data.user_email)
				}
				if (error) {
					if(error === 'AccountAlreadyConfirmed'){
						setErrors({alreadyExist: true})
					}else{
						setErrors({fetching: error})
					}
				}
			setIsFetching(false)
		}
		fetchAccountConfirmed()
	}, [])

	function submitHandler() {
		navigate("/")
	}

	return (
		<>
			<Container maxWidth="sm">
				<Grid container alignItems="center" justifyContent="center" sx={myStyles.container}>
					<Grid item xs={12} sx={myStyles.item}>
						{isFetching && <CircularProgress color="secondary"  size={40} />}
						{!isFetching && errors?.alreadyExist ? <AlreadyConfirmed userEmail={userEmail} />:(
							<>
								{errors?.fetching ? <span className="errorMsg">{t(errors.fetching)}</span> :
								<AlreadyConfirmed userEmail={userEmail} />
								}
							</>
						)}
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export default ConfirmAccount;